import { downloadZip } from "client-zip";
import pLimit from "p-limit";

export class CreateZipFileInteractor {
  public async execute(urls: string[]): Promise<Blob> {
    const limit = pLimit(3);
    const zip = downloadZip(
      await Promise.all(urls.map((url) => limit(() => fetch(url)))),
    );

    return zip.blob();
  }
}
